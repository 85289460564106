import React, {useState} from "react";
import * as Yup from "yup";
import YupPassword from 'yup-password'
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Button,
  Box,
  useTheme
} from "@mui/material";
import {graphql, PageProps} from "gatsby"
import {useFormik, FormikProvider} from "formik";
import {useTranslation} from "gatsby-plugin-react-i18next"
import Logo from "assets/icons/logo.svg";

import {resetPassword} from "services/reset_password";
import PasswordInput from "components/atoms/PasswordInput";

YupPassword(Yup);

export default function ResetPasswordPage({params}: PageProps) {
  const theme = useTheme();
  const {t} = useTranslation();
  const {token} = params;
  const [status, setStatus] = useState("idle");

  const onSubmit = (values: any) => {
    resetPassword(
      values.token,
      values.password,
      (_rsp: any) => {
        setStatus("success");
      },
      () => {
        setStatus("failure");
      }
    );
  };

  const message = (text: string) => {
    return (
      <Container maxWidth="sm">
        <Box sx={{color: theme.palette.primary.main}}>
          <Logo width={150}/>
        </Box>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{minHeight: "50vh"}}
        >
          {text}
        </Grid>
      </Container>
    );
  };

  const formik = useFormik({
    initialValues: {token, password: ""},
    onSubmit,
    validationSchema: Yup.object({
      password: Yup.string()
        .minUppercase(1, t("minUppercase"))
        .min(8, t("minLength"))
        .trim()
        .required(t("fieldRequired")),
    }),
  });


  if (status === "success") {
    return message(t("passwordHasChanged"));
  }

  if (status === "failure") {
    return message(t("passwordfailure"));
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{color: theme.palette.primary.main, p: 2, textAlign: "center"}}>
        <Logo width={250}/>
      </Box>
      <Box
        sx={{
          minHeight: "50vh",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" color="primary" pb={2}>{t("hint")}</Typography>
        <FormikProvider value={formik}>
          <Box component="form" onSubmit={formik.handleSubmit} sx={{width: "100%" }}>
            <FormControl margin="normal" fullWidth>
              <PasswordInput
                variant="outlined"
                name="password"
                label={t("placeholder")}
                fullWidth
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && !!formik.errors.password}
                helperText={
                  formik.touched.password
                    ? formik.errors.password
                    : " "
                }
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <Button
                size="large"
                disabled={!(formik.isValid && formik.dirty)}
                type="submit"
                color="primary"
                variant="contained"
              >
                {t("button")}
              </Button>
            </FormControl>
          </Box>
        </FormikProvider>
      </Box>
    </Container>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index", "reset-password"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;