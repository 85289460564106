import axios from "axios";
import { API_KEY } from "./request";

export function resetPassword(
  token: string,
  password: string,
  success: Function,
  failure: Function
) {
  axios
    .post(
      "/patients/reset_password",
      JSON.stringify({ password: password, token: token, api_key: API_KEY })
    )
    .then(data => success(data))
    .catch(reason => failure(reason));
}
